import type { RouteRecordRaw } from "vue-router"

import { dynamicImportWithRetry } from "@fatso83/retry-dynamic-import"

import {
	CODE_READ,
	MAINTENANCE_READ,
	PLANER_READ,
	TOC_DATA_READ,
	TOC_READ,
	TOC_STAT_READ,
	TRAIN_READ,
	TURNOVER_MILEAGE_CALCULATOR_READ,
	TURNOVER_READ,
	TURNOVER_SCHEDULE_READ
} from "@/utils/mappers"

// !!! dynamicImportWithRetry does not work with import("@/...") syntax - use relative paths instead
const routes: Array<RouteRecordRaw> = [
	{
		path: "/",
		name: "Main",
		components: {
			content: () => dynamicImportWithRetry(() => import("../views/ViewStart.vue"))
		},
		meta: {
			title: "pages.main.title",
			public: false
		}
	},
	{
		path: "/turning-shop",
		name: "TurningShop",
		components: {
			content: () => dynamicImportWithRetry(() => import("../views/ViewStartCenter.vue"))
		},
		meta: {
			title: "pages.turning-shop.title",
			public: false
		}
	},
	{
		path: "/planner",
		name: "Planner",
		redirect: { name: "PlannerOnLine" },
		components: {
			content: () => dynamicImportWithRetry(() => import("../views/ViewPlanner.vue"))
		},
		meta: {
			title: "pages.planner.title",
			public: false,
			permission: PLANER_READ
		},
		children: [
			{
				path: "on-line",
				name: "PlannerOnLine",
				component: () => dynamicImportWithRetry(() => import("../views/ViewPlannerInDepot.vue")),
				meta: {
					title: "pages.planner.on-line.title",
					public: false,
					permission: PLANER_READ
				}
			},
			{
				path: "in-depot",
				name: "PlannerInDepot",
				component: () => dynamicImportWithRetry(() => import("../views/ViewPlannerOnLine.vue")),
				meta: {
					title: "pages.planner.in-depot.title",
					public: false,
					permission: PLANER_READ
				}
			}
		]
	},
	{
		path: "/maintenance",
		name: "Maintenance",
		redirect: { name: "MaintenancePlan" },
		components: {
			content: () => dynamicImportWithRetry(() => import("../views/ViewMaintenance.vue"))
		},
		meta: {
			title: "pages.maintenance.title",
			public: false,
			permission: MAINTENANCE_READ
		},
		children: [
			{
				path: "plan",
				name: "MaintenancePlan",
				component: () => dynamicImportWithRetry(() => import("../views/ViewMaintenancePlan.vue")),
				meta: {
					title: "pages.maintenance.plan.title",
					public: false,
					permission: MAINTENANCE_READ
				}
			},
			{
				path: "fact",
				name: "MaintenanceFact",
				component: () => dynamicImportWithRetry(() => import("../views/ViewMaintenanceFact.vue")),
				meta: {
					title: "pages.maintenance.fact.title",
					public: false,
					permission: MAINTENANCE_READ
				}
			},
			{
				path: "archive",
				name: "MaintenanceArchive",
				component: () => dynamicImportWithRetry(() => import("../views/ViewMaintenanceArchive.vue")),
				meta: {
					title: "pages.maintenance.archive.title",
					public: false,
					permission: MAINTENANCE_READ
				}
			}
		]
	},
	{
		path: "/track-occupation",
		children: [
			{
				path: "",
				name: "TrackOccupation",
				components: {
					content: () => dynamicImportWithRetry(() => import("../views/ViewTrackOccupation.vue"))
				},
				meta: {
					title: "pages.track-occupation.title",
					public: false,
					permission: TOC_READ
				}
			},
			{
				path: "data",
				name: "TrackOccupationData",
				components: {
					content: () => dynamicImportWithRetry(() => import("../views/ViewTrackOccupationData.vue"))
				},
				meta: {
					title: "pages.track-occupation.data.title",
					public: false,
					permission: TOC_DATA_READ
				}
			},
			{
				path: "codes",
				name: "TrackOccupationCodes",
				components: {
					content: () => dynamicImportWithRetry(() => import("../views/ViewTrackOccupationCodes.vue"))
				},
				meta: {
					title: "pages.track-occupation.codes.title",
					public: false,
					permission: CODE_READ
				}
			},
			{
				path: "trains",
				name: "TrackOccupationTrains",
				components: {
					content: () => dynamicImportWithRetry(() => import("../views/ViewTrackOccupationTrains.vue"))
				},
				meta: {
					title: "pages.track-occupation.trains.title",
					public: false,
					permission: TRAIN_READ
				}
			},
			{
				path: "statistics",
				name: "TrackOccupationStatistics",
				components: {
					content: () => dynamicImportWithRetry(() => import("../views/ViewTrackOccupationStatistics.vue"))
				},
				meta: {
					title: "pages.track-occupation.statistics.title",
					public: false,
					permission: TOC_STAT_READ
				}
			}
		]
	},
	{
		path: "/turnover",
		name: "Turnover",
		redirect: { name: "TurnoverSchedule" },
		components: {
			content: () => dynamicImportWithRetry(() => import("../views/ViewTurnover.vue"))
		},
		meta: {
			title: "pages.turnover.title",
			public: false,
			permission: TURNOVER_READ
		},
		children: [
			{
				path: "schedule",
				name: "TurnoverSchedule",
				component: () => dynamicImportWithRetry(() => import("../views/ViewTurnoverSchedule.vue")),
				meta: {
					title: "pages.turnover.schedule.title",
					public: false,
					permission: TURNOVER_SCHEDULE_READ
				}
			},
			{
				path: "calculator",
				name: "TurnoverCalculator",
				component: () => dynamicImportWithRetry(() => import("../views/ViewTurnoverCalculator.vue")),
				meta: {
					title: "pages.turnover.calculator.title",
					public: false,
					permission: TURNOVER_MILEAGE_CALCULATOR_READ
				}
			}
		]
	},
	{
		path: "/auth",
		name: "Auth",
		components: {
			content: () => dynamicImportWithRetry(() => import("../views/ViewAuth.vue"))
		},
		meta: {
			title: "pages.auth.title",
			public: true
		}
	},
	{
		path: "/404",
		name: "NotFound",
		components: {
			content: () => dynamicImportWithRetry(() => import("../views/ViewNotFound.vue"))
		},
		meta: {
			public: true,
			title: "pages.404.title"
		}
	},
	{
		path: "/:pathMatch(.*)*",
		redirect: "/404"
	}
]

export default routes
