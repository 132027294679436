import { captureMessage } from "@sentry/vue"
import { createRouter, createWebHistory } from "vue-router"

import { useAppStore } from "@/store/app"

import { handleUnauthorizedAccess, updatePageTitleAndMeta } from "@/router/functions"
import routes from "@/router/routes"

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition
		} else {
			return { left: 0, top: 0 }
		}
	}
})

router.beforeEach(async (to) => {
	const appStore = useAppStore()
	appStore.routerLoading(true)
	updatePageTitleAndMeta(document, to)
	const route = await handleUnauthorizedAccess(to)
	appStore.routerLoading(false)
	return route
})

router.afterEach((to, from, failure) => {
	if (failure) {
		captureMessage(`router failure: from ${from.fullPath} to ${to.fullPath} - ${failure}`)
	}
})

// Workaround for https://github.com/vitejs/vite/issues/11804
router.onError((err, to) => {
	if (err?.message?.includes?.("Failed to fetch dynamically imported module")) {
		if (!localStorage.getItem("vuetify:dynamic-reload")) {
			localStorage.setItem("vuetify:dynamic-reload", "true")
			location.assign(to.fullPath)
		}
	}
})

router.isReady().then(() => {
	localStorage.removeItem("vuetify:dynamic-reload")
})

export default router
