<script lang="ts" setup>
import { mdiLogout, mdiCalendar, mdiAccountCircleOutline } from "@mdi/js"
import { computed, nextTick, onMounted, ref } from "vue"
import { useRoute, useRouter } from "vue-router"
import { useDisplay, useTheme } from "vuetify"

import type { TypeIcons } from "@/types"

import { useAppStore } from "@/store/app"
import { useUserStore } from "@/store/user"

import DrawerNavigation from "@/components/Drawers/DrawerNavigation.vue"
import BtnToggleTheme from "@/components/Inputs/BtnToggleTheme.vue"
import MenuUser from "@/components/Menus/MenuUser.vue"

import ErrorSnackbar from "@/components/ErrorSnackbar.vue"

import authService from "@/utils/auth/auth.service"
import logoLight from "@/utils/images/logo/logo_c.png"
import logoDark from "@/utils/images/logo/logo_w.png"

const router = useRouter()
const route = useRoute()
const appStore = useAppStore()
const userStore = useUserStore()
const display = useDisplay()
const theme = useTheme()

const icons: TypeIcons = {
	logout: mdiLogout,
	calendar: mdiCalendar,
	account: mdiAccountCircleOutline
}

const drawer = ref(false)

const logo = computed(() => (theme.global.current.value.dark ? logoDark : logoLight))
const notAuthPage = computed(() => route.name !== "Auth")
const isLoggedIn = computed(() => userStore.isLoggedIn)
const notMainPage = computed(() => route.name !== "Main")
const showNavMenuBtn = computed(() => display.smAndDown.value && notMainPage.value)

const logout = () => {
	authService.logout()
}

function onResize() {
	nextTick(() => {
		appStore.onResize()
		drawer.value = display.mdAndUp.value
	})
}

onMounted(() => {
	onResize()
})
</script>

<template>
	<v-app id="app_root" v-resize="onResize">
		<v-app-bar
			density="compact"
			:color="notAuthPage ? undefined : 'transparent'"
			elevation="0"
			:class="{
				app_bar: notAuthPage
			}"
		>
			<template v-if="notAuthPage && isLoggedIn">
				<v-app-bar-nav-icon v-if="showNavMenuBtn" variant="text" @click="drawer = !drawer" />
				<router-link :to="{ name: 'Main' }" style="padding: 0 10px">
					<v-img alt="ВСМ-Сервис" :src="logo" width="100" :cover="true" />
				</router-link>
			</template>
			<template #append>
				<BtnToggleTheme />
				<MenuUser v-if="notAuthPage && isLoggedIn" :user="userStore.getUser">
					<v-divider />
					<v-list>
						<v-list-item v-if="route.name !== 'Main'" title="На главную" @click="router.push({ name: 'Main' })">
							<template #prepend>
								<v-icon>
									{{ icons.calendar }}
								</v-icon>
							</template>
						</v-list-item>
						<v-list-item title="Выйти" @click="logout">
							<template #prepend>
								<v-icon>
									{{ icons.logout }}
								</v-icon>
							</template>
						</v-list-item>
					</v-list>
				</MenuUser>
			</template>
		</v-app-bar>
		<DrawerNavigation v-if="notAuthPage && notMainPage && isLoggedIn" v-model="drawer" />
		<ErrorSnackbar />
		<v-main>
			<v-overlay v-if="appStore.isRouterLoading" :model-value="true" class="align-center justify-center">
				<v-progress-circular indeterminate size="64" />
			</v-overlay>
			<router-view v-slot="{ Component }" name="content">
				<keep-alive>
					<component :is="Component" />
				</keep-alive>
			</router-view>
		</v-main>
	</v-app>
</template>

<style lang="scss" scoped>
.app_bar {
	border-color: rgba(var(--v-border-color), var(--v-border-opacity));
	border-style: none none solid none;
	border-width: 1px;
}
</style>
