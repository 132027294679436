<script setup lang="ts">
import { mdiChevronDown, mdiChevronLeft, mdiChevronRight, mdiChevronUp } from "@mdi/js"
import { ref } from "vue"
import { useRoute, useRouter } from "vue-router"
import { useDisplay } from "vuetify"

import type { TypeIcons } from "@/types"

import { useAppStore } from "@/store/app"

import { can } from "@/utils/helpers"

const router = useRouter()
const route = useRoute()
const appStore = useAppStore()
const display = useDisplay()

const hideDrawer = display.smAndDown
const drawer = defineModel<boolean>()
const rail = ref(!hideDrawer)
const icons: TypeIcons = {
	right: mdiChevronRight,
	left: mdiChevronLeft,
	up: mdiChevronUp,
	down: mdiChevronDown
}
const pathIncludes = (path: string, routeName?: string) => {
	return path.includes(router.resolve({ name: routeName }).path)
}
const pathExact = (path: string, routeName?: string) => {
	return path === router.resolve({ name: routeName }).path
}
</script>

<template>
	<v-navigation-drawer
		v-model="drawer"
		:rail="hideDrawer ? false : rail"
		:mobile-breakpoint="'md'"
		:width="267"
		@update:rail="rail = $event"
	>
		<v-list :nav="true">
			<template v-for="page in appStore.getPages" :key="page.routeName">
				<v-list-group v-if="page.pages" :value="page.routeName" :fluid="hideDrawer ? false : rail">
					<template #activator="{ props, isOpen }: { props: any; isOpen: boolean }">
						<v-list-item
							v-bind="{ class: props.class, id: props.id }"
							:prepend-icon="page.icon"
							:disabled="page.disabled"
							:active="isOpen ? pathExact(route.path, page.routeName) : pathIncludes(route.path, page.routeName)"
							active-class="active"
							@click="router.push({ name: page.routeName })"
						>
							<v-list-item-title :title="page.title">{{ page.title }}</v-list-item-title>
							<template #append>
								<v-icon @click.stop="props.onClick">{{ isOpen ? icons.up : icons.down }}</v-icon>
							</template>
						</v-list-item>
					</template>
					<v-list-item
						v-for="{ icon, title, routeName, disabled } in page.pages.filter(({ permission }) => can(permission))"
						:key="routeName"
						:prepend-icon="icon"
						:value="routeName"
						:active="pathIncludes(route.path, routeName)"
						:disabled="disabled"
						@mousedown.stop
						@click="router.push({ name: routeName })"
					>
						<v-list-item-title :title="title">{{ title }}</v-list-item-title>
					</v-list-item>
					<v-divider class="my-5" />
				</v-list-group>
				<v-list-item
					v-else
					:key="page.routeName"
					:prepend-icon="page.icon"
					:value="page.routeName"
					:active="pathIncludes(route.path, page.routeName)"
					:disabled="page.disabled"
					@click="router.push({ name: page.routeName })"
				>
					<v-list-item-title :title="page.title">{{ page.title }}</v-list-item-title>
				</v-list-item>
			</template>
		</v-list>
		<template v-if="!hideDrawer" #append>
			<v-list :nav="true">
				<v-list-item :prepend-icon="rail ? icons.right : icons.left" @click="rail = !rail">
					<v-list-item-title>Скрыть</v-list-item-title>
				</v-list-item>
			</v-list>
		</template>
	</v-navigation-drawer>
</template>

<style scoped lang="scss">
.active:deep(.v-list-item__overlay) {
	opacity: calc(var(--v-activated-opacity) * var(--v-theme-overlay-multiplier)) !important;
}
</style>
