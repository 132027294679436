import type { App } from "vue"
import type { Router } from "vue-router"

import * as Sentry from "@sentry/vue"
export default function initSentry(app: App, router: Router) {
	console.info("MODE:", import.meta.env.MODE)
	Sentry.init({
		environment: import.meta.env.MODE,
		app,
		dsn: import.meta.env.VITE_SENTRY_CONFIG_DSN,
		integrations: [],
		// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
		tracePropagationTargets: [import.meta.env.VITE_API_URL_BASE],

		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		// We recommend adjusting this value in production
		tracesSampleRate: 1.0,

		// Capture Replay for 10% of all sessions,
		// plus for 100% of sessions with an error
		replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		// If you're not already sampling the entire session, change the sample rate to100% when sampling sessions where errors occur.
		replaysOnErrorSampleRate: 1.0,

		trackComponents: true,

		beforeSend: (event, hint) => {
			if (import.meta.env.DEV) {
				// eslint-disable-next-line no-console
				console.error(hint.originalException || hint.syntheticException)
				return null // this drops the event and nothing will be sent to sentry
			}
			return event
		}
	})
	if (import.meta.env.PROD) {
		Sentry.addIntegration(Sentry.replayIntegration())
		Sentry.addIntegration(Sentry.browserTracingIntegration({ router }))
	}
}
