import { captureException, captureMessage } from "@sentry/vue"
import axios, { type AxiosResponse } from "axios"
import { defineStore, getActivePinia } from "pinia"

import type { TypeAuthToken } from "@/types"

import router from "@/router"
import { StorageService } from "@/utils/storage/storage.service"

const authApi = axios.create({
	baseURL: import.meta.env.VITE_API_URL + "auth/"
})

type AuthenticationData = {
	url: string
	data: {
		email?: string
		code?: string
		deviceId?: string
		refresh?: string
	}
}
export class AuthService {
	uid: string
	storageService = new StorageService()

	constructor() {
		this.uid = this.generateDeviceId()
	}
	generateDeviceId() {
		return window.navigator.userAgent.replace(/\D+/g, "")
	}

	setTokenData(data: {
		accessToken: string
		refreshToken: string
		accessTokenExpiresIn: number
		refreshTokenExpiresIn: number
	}) {
		if (!data.accessToken || !data.refreshToken || !data.accessTokenExpiresIn || !data.refreshTokenExpiresIn) {
			captureMessage(`auth:setTokenData - no token data`)
			return false
		}

		this.storageService.setAccessToken(data.accessToken)

		this.storageService.setRefreshToken(data.refreshToken)

		const accessExpirationDate = Date.now() + data.accessTokenExpiresIn * 1000 - 5000
		this.storageService.setAccessTokenExpirationDate(accessExpirationDate)

		const refreshExpirationDate = Date.now() + data.refreshTokenExpiresIn * 1000 - 5000
		this.storageService.setRefreshTokenExpirationDate(refreshExpirationDate)

		return true
	}

	async getValidAccessToken() {
		if (this.isAccessTokenExpired()) {
			const success = await this.refreshTokenData()
			if (!success) {
				captureMessage(
					`auth:getValidAccessToken - refresh token failed token expiration date: ${this.storageService.getRefreshTokenExpirationDate()} token value: ${this.storageService.getRefreshToken()} `
				)
				return null
			}
		}
		return this.storageService.getAccessToken()
	}

	isAccessTokenExpired() {
		const accessTokenExpirationDate = this.storageService.getAccessTokenExpirationDate()
		if (accessTokenExpirationDate === null) return true
		return Date.now() > Number(accessTokenExpirationDate)
	}

	async refreshTokenData() {
		if (this.isRefreshTokenExpired()) {
			captureMessage("auth:refreshTokenData - refresh token is expired")
			return false
		}
		const refreshToken = this.storageService.getRefreshToken()
		if (refreshToken !== null) {
			return await this.authenticate({
				url: "refresh/",
				data: {
					refresh: refreshToken
				}
			})
		}
		captureMessage("auth:refreshTokenData - refresh token is null")
		return false
	}

	isRefreshTokenExpired() {
		const refreshTokenExpirationDate = this.storageService.getRefreshTokenExpirationDate()
		if (refreshTokenExpirationDate === null) return true
		return Date.now() > Number(refreshTokenExpirationDate)
	}
	async authenticate({ url, data }: AuthenticationData) {
		try {
			const response = await authApi.post(url, data)
			if (!response.data) {
				captureMessage(`auth:authenticate - response.data is null`)
				return false
			} else {
				this.setTokenData(response.data)
				return true
			}
		} catch (e) {
			captureMessage("auth:authenticate - error on request")
			captureException(e)
			return false
		}
	}

	async login({ email, code }: { email: string; code: string }) {
		return this.authenticate({
			url: "login/",
			data: { email: email.toLowerCase() + "@vsmservice.ru", code, deviceId: this.uid }
		})
	}

	async code(email: string) {
		try {
			await authApi.post("code/", {
				email: email.toLowerCase() + "@vsmservice.ru",
				deviceId: this.uid
			})
			return true
		} catch (e) {
			captureMessage("auth:code - error on code request")
			captureException(e)
			return false
		}
	}
	async logout(redirect = true) {
		//authApi.post("logout/", { deviceId: this.uid }) //server
		this.storageService.clear() //localstore
		try {
			const activePinia = getActivePinia()
			if (activePinia) {
				Object.entries(activePinia.state.value).forEach(([storeName, state]) => {
					const storeDefinition = defineStore(storeName, state)
					const store = storeDefinition(activePinia)
					store.$reset()
				})
			}
		} catch (e) {
			captureMessage("auth:logout - user data not cleaned up")
			captureException(e)
		}
		if (redirect && router.currentRoute.value.name !== "Auth") router.go(0)
	}
}

export default new AuthService()
