<script lang="ts" setup>
import { computed } from "vue"
import { useI18n } from "vue-i18n"

import { useAppStore } from "@/store/app"
const appStore = useAppStore()
const { t } = useI18n()
const model = computed({
	get: () => appStore.snackbar,
	set: (value: boolean) => {
		appStore.setSnackbar(value)
	}
})
</script>

<template>
	<v-snackbar v-model="model" :timeout="-1" :color="appStore.snackbarType" :multi-line="true">
		<!-- eslint-disable-next-line vue/no-v-html -->
		<span style="white-space: pre-line" v-html="appStore.snackbarMessage" />
		<template #actions>
			<v-btn variant="text" rounded @click="model = false">
				{{ t("snackbar.close-btn") }}
			</v-btn>
		</template>
	</v-snackbar>
</template>
