import type { TypeMapping, TypeMappingReverse, TypePartialOccupation, TypePartialTrain } from "@/types"

export function reverseMapping<T>(mapping: TypeMapping<T>): TypeMappingReverse<T> {
	const result: TypeMappingReverse<T> = {}
	Object.keys(mapping).forEach((key) => {
		const value = mapping[key as keyof T]
		result[value] = key as keyof T
		return result
	})
	return result
}

export const mappingOrderingOccupation: TypeMapping<TypePartialOccupation> = {
	user: "user__last_name",
	train: "train__name",
	track_name: "track_name",
	downtime: "downtime",
	lastChangeDate: "lastChangeDate",
	in_out_man: "in_out_man",
	code: "code__name",
	start: "start",
	finish: "finish",
	workshop: "workshop__name",
	depot: "depot__name"
}

export const mappingOrderingTrain: TypeMapping<TypePartialTrain> = {
	name: "name",
	count_cars: "count_cars",
	depot: "depot__name",
	mileage: "mileage",
	mileage_date: "mileage_date",
	inspection: "inspection",
	inspection_date: "inspection_date",
	start_date: "start_date",
	wo_status: "wo_status",
	wo: "wo"
}

export const AxiosAbortReasonUnauthorized = "unauthorized"
export const AxiosAbortReasonParallel = "parallel"

// PERMISSIONS
export const TOC_READ = "BUSY_PATH_READ"
export const TOC_CREATE = "BUSY_PATH_POSITION_INSERT"
export const TOC_DATA_READ = "DATA_READ"
export const TOC_DATA_DOWNLOAD = "DATA_DOWNLOAD"
export const TOC_DATA_UPDATE = "DATA_UPDATE"
export const TOC_STAT_READ = "STATISTIC_READ"
export const TOC_STAT_DOWNLOAD = "STATISTIC_DOWNLOAD"
export const TOC_STAT_DETAIL_READ = "STATISTIC_DETAIL_READ"
export const TOC_STAT_DETAIL_DOWNLOAD = "STATISTIC_DETAIL_DOWNLOAD"
export const TRAIN_READ = "TRAIN_READ"
export const TRAIN_RELOCATE = "TRAIN_RELOCATE"
export const CODE_READ = "CODE_READ"
export const PLANER_READ = "PLANER_READ"
export const PLANER_TRAIN_CARD_UPDATE = "TRAIN_CARD_UPDATE"
export const PLANER_UPDATE = "PLANER_UPDATE"
export const MAINTENANCE_READ = "PLAN_FACT_READ"
export const MAINTENANCE_UPDATE = "PLAN_FACT_UPDATE"
export const TURNOVER_READ = "TIMETABLE_REPAIR_READ"
export const TURNOVER_SCHEDULE_READ = "TIMETABLE_TURNOVER_READ"
export const TURNOVER_SCHEDULE_UPDATE = "TIMETABLE_TURNOVER_UPDATE"
export const TURNOVER_MILEAGE_CALCULATOR_READ = "MILEAGE_CALCULATE_READ"
export const TURNOVER_MILEAGE_CALCULATOR_UPDATE = "MILEAGE_CALCULATE_UPDATE"
