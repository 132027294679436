import axios from "axios"

import {
	axiosRequestInterceptorOnFulfilled,
	axiosRequestInterceptorOnRejected,
	axiosResponseInterceptorOnRejected
} from "@/api/functions"

const ApiDoc = axios.create({
	baseURL: import.meta.env.VITE_API_URL
})

ApiDoc.interceptors.request.use(axiosRequestInterceptorOnFulfilled, axiosRequestInterceptorOnRejected)

ApiDoc.interceptors.response.use(async function (response) {
	if (response.status === 200 || response.status === 201) response.shouldDownload = true
	return response
}, axiosResponseInterceptorOnRejected)

export default ApiDoc
